








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';

@Component({
  components: {
    CommonIcon,
  },
})
export default class JwlButton extends Vue {
  @Prop(Object)
  toRoute!: Route | undefined;

  @Prop(String)
  linkUrl!: string;

  @Prop({ default: 'regular' })
  iconStyle!: string;

  @Prop(String)
  iconName!: string | null;

  @Prop(Boolean)
  iconAtEnd!: boolean;

  @Prop(Boolean)
  buttonBordered!: boolean;

  @Prop(Boolean)
  buttonTab!: boolean;

  @Prop(Boolean)
  buttonArea!: boolean;

  @Prop(Boolean)
  buttonSmall!: boolean;

  @Prop(Boolean)
  buttonLarge!: boolean;

  @Prop(Boolean)
  buttonDisabled!: boolean;

  @Prop(Boolean)
  buttonActive!: boolean;

  @Prop(Boolean)
  marginLeft!: boolean;

  @Prop(Boolean)
  marginRight!: boolean;

  @Prop(Boolean)
  marginAuto!: boolean;

  @Prop(Boolean)
  fullWidth!: boolean;

  emitClick (): void {
    this.$emit('button-clicked');
  }

  get buttonClass (): Record<string, boolean> {
    return {
      'jwl-button--bordered': this.buttonBordered,
      'jwl-button--has-icon': this.hasIcon,
      'jwl-button--icon-at-end': this.iconAtEnd,
      'jwl-button--small': this.buttonSmall,
      'jwl-button--large': this.buttonLarge,
      'jwl-button--tab': this.buttonTab,
      'jwl-button--area': this.buttonArea,
      'jwl-button--margin-left': this.marginLeft,
      'jwl-button--margin-right': this.marginRight,
      'jwl-button--margin-auto': this.marginAuto,
      'jwl-button--disabled': this.buttonDisabled,
      'jwl-button--active': this.buttonActive,
      'jwl-button--full-width': this.fullWidth,
    };
  }

  get isLink (): boolean {
    return !!this.linkUrl && !this.buttonDisabled;
  }

  get isRouterLink (): boolean {
    return !!this.toRoute && !this.buttonDisabled;
  }

  get hasIcon (): boolean {
    return !!this.iconName;
  }
}
