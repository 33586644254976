





















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunUnit } from 'common-modules/src/store/interface/RunUnit';
import { mapGetters } from 'vuex';
import { Unit } from 'common-modules/src/store/interface/Unit';
import { ExtendedCourse, ExtendedRunCourse } from '@/store/interface/Course';
import JwlButton from '@/components/JwlButton.vue';
import { ExtendedRunConcentration } from '@/store/interface/Concentration';

const JwlToggle = () => import('@/components/jwlToggle.vue');
const JwlShowMore = () => import('@/components/jwlShowMore.vue');
const LmsCourseDocument = () => import('@/components/LmsCourseDocument.vue');
const RunCourseLegend = () => import('@/components/RunCourseLegend.vue');
const JwlCourseTasks = () => import('@/components/jwlCourseTasks.vue');
const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    JwlCourseTasks,
    JwlButton,
    JwlToggle,
    JwlShowMore,
    LmsCourseDocument,
    RunCourseLegend,
    CommonIcon,
    CommonError,
  },
  computed: mapGetters([
    'concentration',
  ]),
})
export default class JwlCourse extends Vue {
  concentration!: ExtendedRunConcentration;

  @Prop(Object)
  masterCourse!: ExtendedCourse;

  @Prop(Object)
  runCourse!: ExtendedRunCourse;

  @Prop(Boolean)
  showToggle!: boolean;

  @Prop(String)
  unitDetailPathName!: string;

  @Prop(String)
  courseLoadingUrl!: string;

  open = false;
  loadingStatus = 0;
  loadedUnits: Unit[] | RunUnit[] | null = null;
  error = null;
  openStudentDocuments = false;
  openOnlineFacilitatorDocuments = false;
  openOnsiteFacilitatorDocuments = false;

  toggleOpen (): void {
    if (!this.open) {
      if (this.loadingStatus < 2 && this.courseLoadingUrl) {
        this.loadingStatus = 1;
        this.$store.dispatch('getData', this.courseLoadingUrl)
          .then((data) => {
            this.loadedUnits = data.unit;
            this.loadingStatus = 2;
          })
          .catch((e) => {
            this.error = e;
          });
      }
    }

    this.open = !this.open;
  }

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  unitDetailRoute (unit: RunUnit | Unit): Record<string, any> {
    return {
      name: this.unitDetailPathName,
      params: {
        lang: this.$route.params.lang,
        unit: this.runCourse ? unit.code : unit.id.toString(),
      },
    };
  }

  showUnitLinkButton (unit: RunUnit): boolean {
    if (unit.startDate) {
      const now = new Date();
      const unitStart = new Date(unit.startDate);
      return now >= unitStart;
    }

    return true;
  }

  unitClass (unit: RunUnit | Unit): string {
    if ('startDate' in unit && unit.startDate && unit.endDate) {
      const now = new Date();
      const startDate = new Date(unit.startDate);
      const endDate = new Date(unit.endDate);
      return startDate <= now && endDate >= now
        ? 'jwl-course__unit-container--current'
        : '';
    }

    return '';
  }

  get jwlCourse (): ExtendedCourse | ExtendedRunCourse {
    return this.masterCourse || this.runCourse;
  }

  get descriptionAndObjective (): string {
    let html = this.jwlCourse.description;
    if (this.jwlCourse.objective) {
      html += this.jwlCourse.objective;
    }
    return html;
  }

  get units (): RunUnit[] | Unit[] {
    return this.loadedUnits || this.jwlCourse.units;
  }

  created (): void {
    const now = new Date();
    let afterStart = false;
    let beforeEnd = false;
    if (this.runCourse?.startDate) {
      const start = new Date(this.runCourse.startDate);
      afterStart = now.getTime() > start.getTime();
    }

    if (this.runCourse?.endDate) {
      const end = new Date(this.runCourse.endDate);
      beforeEnd = now.getTime() < end.getTime();
    }

    this.open = (afterStart && beforeEnd) || !this.showToggle;
  }
}
